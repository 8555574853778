<template>
  <div class="class_case">
    <h3 class="mark"></h3>
    <el-table
      v-loading="loading"
      :data="tableData"
      style="width: 100%; margin-bottom: 20px"
      row-key="keChengNumber"
      default-expand-all
      :row-class-name="tableRowClassName"
      border
      :tree-props="{
        children: 'children',
        hasChildren: 'hasChildren',
      }"
      @cell-click="cellClickAction"
    >
      <el-table-column prop="class_Name" label="班级" sortable width="180">
      </el-table-column>
      <el-table-column prop="user_Name" label="讲师" sortable width="180">
      </el-table-column>
      <el-table-column prop="course_Name" label="阶段名"> </el-table-column>
      <el-table-column prop="keChengName" label="课程名"> </el-table-column>
      <el-table-column prop="examTime" label="考试时间"> </el-table-column>
      <el-table-column prop="test" label="每日一考">
        <template slot-scope="scope">
          <div v-if="scope.row.test == '已完成'" class="cell">已完成</div>
          <div v-if="scope.row.test == '未完成'" class="cell1">未完成</div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "ClassCase",
  data() {
    return {
      tableData: [],
    };
  },
  computed: {
    ...mapState(["loading"]),
  },
  methods: {
    tableRowClassName({ row }) {
      if (row.knowexam_Id == "未完成") {
        return "warning-row";
      } else {
        return "";
      }
    },
    async cellClickAction(row) {
      if (row.test == "已完成") {
        this.$router.push({
          name: "ClassTest",
          params: {
            knowexam_Id: row.knowexam_Id,
            class_Id: this.tableData[0].class_Id,
          },
          query: { kecheng: row.keChengName, jieduan: row.course_Name },
        });
      }
    },
  },
  async created() {
    //设置loading
    this.$datas.meta = {
      course_Id: this.$route.params.course_Id,
      class_Id: this.$route.params.class_Id,
    };
    let res = await this.$datas.jieduan;
    let query = this.$route.query;

    this.$datas.meta = {
        className:this.$route.query.class_Name
    };
    let res1 = await this.$datas.kechengByClassName;
    let classNamesTemp = res1.data.msg.data[this.$route.query.course_Name];
    let classNames = [];
    for (let i in classNamesTemp) {
      if (!/^综合项目/.test(classNamesTemp[i].name)) {
        classNames.push(classNamesTemp[i]);
      }
    }
    if (res.status == 200) {
      let tabledata = [
        {
          class_Id: query.class_Id,
          class_Name: query.class_Name,
          user_Name: `总课程：${classNames.length}天`,
          course_Name: `已完成：${res.data.msg.data.length}天`,
          keChengName: `未完成：${
            classNames.length - res.data.msg.data.length
          }天`,
          children: [],
        },
      ];
      console.log('----------------------------')
      console.log(res1);
      console.log(res.data.msg.data);
      for (let j in classNames) {
        let indexF = -1;
        for (let i in res.data.msg.data) {
          if (res.data.msg.data[i].keChengName == classNames[j].name) {
            indexF = i;
            break;
          }
        }
        if (indexF != -1) {
          tabledata[0].children.push({
            class_Name: query.class_Name,
            user_Name: query.user_Name,
            course_Name: query.course_Name,
            ...res.data.msg.data[indexF],
            test: "已完成",
          });
        } else {
          tabledata[0].children.push({
            class_Name: query.class_Name,
            user_Name: query.user_Name,
            course_Name: query.course_Name,
            keChengName: classNames[j].name,
            keChengNumber: classNames[j].dayNumber,
            knowexam_Id: null,
            test: "未完成",
          });
        }
      }
      //设置数据
      this.tableData = tabledata;
    }
  },
};
</script>
<style lang="less" scoped>
.class_case {
  padding: 5px;
}
.el-table .warning-row {
  background: oldlace;
}
.el-table .warning-row td:last-of-type .cell {
  color: #909399;
}
.el-table .warning-row td:last-of-type .cell:hover {
  cursor: default;
}
.content .el-table__row td:last-of-type .cell {
  color: #409eff;
}
.el-table__row td:last-of-type .cell:hover {
  cursor: pointer;
}
.content .el-table__row td:last-of-type .cell1 {
  color: #555;
  padding: 0 10px;
}
.el-table__row td:last-of-type .cell1:hover {
  cursor: not-allowed;
}
</style>
